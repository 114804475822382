import DefaultTemplate from '@templates/default';
import { getClient } from '@sanity-lib/client';
import { homePageQuery } from '@sanity-lib/queries/pages';
import { fetchWithMenu } from '@sanity-lib/util/queries';
import { checkForAndExecuteDocQueries } from '@util/content';

export async function getStaticProps({ draftMode = false }) {
  const client = getClient({ preview: draftMode });

  const { menu, contentNotQueried } = await fetchWithMenu(client, {
    contentNotQueried: homePageQuery,
  });

  if (contentNotQueried) {
    const content = await checkForAndExecuteDocQueries(
      contentNotQueried,
      client,
    );
    return {
      props: { menu, content, draftMode },
    };
  } else {
    return {
      notFound: true,
    };
  }
}

export default function Page(props) {
  return <DefaultTemplate {...props} />;
}
