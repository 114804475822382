import Blocks, { HeroComponents } from '@blocks';
import Layout from '@components/Layout';

import styles from './templateStyles.module.css';

const DefaultTemplate = ({ content, menu }) => {
  const heroTheme = content?.hero?.theme || null;

  return (
    <Layout heroTheme={heroTheme} menu={menu}>
      {content?.hero &&
        (() => {
          const Component = HeroComponents[content.hero.type];

          return <Component {...content.hero} />;
        })()}
      <main className={styles.main}>
        {content?.blocks?.map((block, i) => {
          const Block = Blocks[block.type];
          return <Block key={i} {...block} />;
        })}
      </main>
    </Layout>
  );
};

export default DefaultTemplate;
